@import url('./normalize.css');
@import url('./root/root.css');

.App {
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 60px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

@media (max-width: 769px) {
  .App {
    gap: 40px;
    padding: 0 20px;
  }
}
