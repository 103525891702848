@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800;900&display=swap');

:root {
  --main-color: rgb(0, 0, 0);
  --second-color: rgb(31, 33, 35);
  --primary: rgb(214, 254, 94);
  --hover: rgb(177, 211, 72);
  --main-text-color: rgb(255, 255, 255);
  --alert: rgb(255, 64, 110);
}

::-webkit-scrollbar {
  width: 10px;
  cursor: grab !important;
}

::-webkit-scrollbar-track {
  background-color: var(--second-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 12px;
}

*, li {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
a {
  color: inherit;
}

header {
  padding-top: 40px;
}

main {
  flex: 1 0 auto;
}

footer {
  padding-bottom: 40px;
}

#root {
  font-family: 'Inter', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  background-color: var(--main-color);
  min-height: 100vh;
}
